import {
  CognitoUser,
  CognitoUserPool,
  CookieStorage,
  ICookieStorageData,
} from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import moment from "moment";

let domain: any = process.env.REACT_APP_COOKIE_URL;
// UserPoolId: process.env.REACT_APP_COGNITO_ID, // Your user pool id here
// ClientId: process.env.REACT_APP_COGNITO_SECRET,
// console.log(process.env)

// let domain: any = "injazatadmin.demoz.agency";  //For Build Deployment
// let domain: any = "localhost";  //For Local

// console.log(process.env)

export const congitoConfig: any = {
  region: "ap-south-1",
  userPoolId: process.env.REACT_APP_COGNITO_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_SECRET,
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: domain,
    // OPTIONAL - Cookie path
    path: "/",
    // OPTIONAL - Cookie expiration in days
    expires: 1,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: process.env.ENVIRONMENT == "development" ? false : true,
  },
};

export let poolData: any = {
  UserPoolId: process.env.REACT_APP_COGNITO_ID, // Your user pool id here
  ClientId: process.env.REACT_APP_COGNITO_SECRET, // Your client id here,
  Storage: new CookieStorage({
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: domain,
    // OPTIONAL - Cookie path
    path: "/",
    // OPTIONAL - Cookie expiration in days

    expires: 1,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: "strict",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: process.env.ENVIRONMENT == "development" ? false : true,
  }),
};

var userPool: any = new CognitoUserPool(poolData);

var cognitoUser: any = userPool.getCurrentUser();

export function isvalid(): boolean {
  userPool = userPool ? userPool : new CognitoUserPool(poolData);

  cognitoUser = cognitoUser ? cognitoUser : userPool.getCurrentUser();

  try {
    var session = cognitoUser.getSession(async function (
      err: any,
      dataSession: any
    ) {
      if (dataSession) {
        cognitoUser.setSignInUserSession(dataSession);
        return dataSession.isValid();
      } else {
        return false;
      }

    });

    return session;
  } catch (error) {
    return false;
  }
}

async function checkIfAdmin() {
  userPool = userPool ? userPool : new CognitoUserPool(poolData);

  cognitoUser = cognitoUser ? cognitoUser : userPool.getCurrentUser();
  var data = await Auth.userAttributes(cognitoUser);

  var is_admin = data.find((x: any) => x.Name === "custom:is_admin");
  return is_admin;
}

export function signOut() {
  Auth.configure(congitoConfig)
  cognitoUser = cognitoUser ? cognitoUser : userPool.getCurrentUser();
  Auth.signOut()
  localStorage.clear();
  sessionStorage.clear();
  console.log("cognito", cognitoUser)
  cognitoUser?.signOut();
}

export function getUser() {
  let userPool = new CognitoUserPool(poolData);
  try {
    cognitoUser = userPool.getCurrentUser();
    cognitoUser.setSignInUserSession(getSession());
    return cognitoUser;
  } catch (error) {
    return null;
  }
}

export function getSession(): any {
  let userPool = new CognitoUserPool(poolData);
  try {
    cognitoUser = userPool.getCurrentUser();
  } catch (error) {
    return false;
  }
  try {
    var session = cognitoUser.getSession(function (err: any, data: any) {
      return data;
    });
    return session;
  } catch (error) {
    return false;
  }
}

export function getUserName(valarry: any) {
  if (valarry && valarry.length && valarry.length > 0) {
    return valarry.find((x: any) => x.Name == "name").Value;
  } else {
    return "";
  }
}

function getCompanyId(valarry: any) {
  if (valarry && valarry.length && valarry.length > 0) {
    return valarry.find((x: any) => x.Name == "custom:customer_account_no")
      .Value;
  } else {
    return "";
  }
}

export function getRoleId(valarry: any) {
  if (valarry && valarry.length && valarry.length > 0) {
    return valarry.find((x: any) => x.Name == "custom:roleId").Value;
  } else {
    valarry["custom:roleId"]
  }
}

export function getEmail(valarry: any) {
  if (valarry && valarry.length && valarry.length > 0) {
    return valarry.find((x: any) => x.Name == "email").Value;
  } else {
    return sessionStorage.getItem("customer_email");
  }
}



export const isValidUser = (): boolean => {
  let generatedTime: any = sessionStorage.getItem("token_generated");

  if (moment().diff(moment.unix(generatedTime), "minutes") < 50) {
    return true;
  } else {
    return false;
  }
};
