/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

// import { configureAppStore } from 'store/configureStore';
import { store } from 'store';

import reportWebVitals from 'reportWebVitals';

import { ThemeProvider } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, CssBaseline, StyledEngineProvider } from '@material-ui/core';

import themes from 'styles/themes';
import { permissionsx } from 'data/permissions'
// Initialize languages
import './locales/i18n';
import userPermissions from 'store/actions/permission';
import userPermissionsX from 'store/actions/permissionX';
import { getRoles } from 'services/userServices';
import { toast } from 'react-toastify';
import { signOut } from "services/cognitouser";
import { useHistory } from "react-router-dom";
import { spinLoaderShow } from 'store/actions/spinLoader';
import { logoutAction } from 'store/actions/logoutAction';
import { isvalid } from 'services/cognitouser';
import { BroadcastChannel } from 'broadcast-channel';

export const Application = () => {
  const [load,setLoad] = useState(false)
  const customization = useSelector((state: any) => state.customization);
  // const permissionsData = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const userChannel = new BroadcastChannel('user');

 
  const checkRoles = () => {
    // setLoad(true)
    let roleId = localStorage.getItem("roleId");
    // dispatch(userPermissions(permissionsx));
        
    if (roleId) {
      getRoles({ uuid: roleId }).then((res: any) => { 
        if (res[0]["status"]) {  
          dispatch(userPermissions(res[0]["permissions"]));
          dispatch(userPermissionsX(res[0]["permissions"]));
          setLoad(false)
  
        } else {
            userChannel.postMessage({
              userId: "", // If the user opened your app in multi-tabs and signed-in with multi accounts, you need to put the userId here to identify which account has signed out exactly
              payload: {
                  type: "SIGN_OUT"
              }
          });
          toast.error("Your role is disabled! Contact Admin");
          setLoad(false)
        }
      })
    }

  }

  useEffect(() => {
    //console.log("hhhhhhhhhhhhhhhhh")
    // console.log("envvvvvvvvvvvvvv", process?.env)
    checkRoles();

    //GET FROM SessionStorage
    // console.log("heyyyyy", process.env)
    userChannel.onmessage = (data:any)=> { 
       console.log("haa",data)
      if(data?.payload?.type === "SIGN_OUT") {
        // As I talked before about multi-accounts, we need to check the current user id with the sent userId by the userChannel and if they were the same, we have to dispatch the userSignOut action.
        console.log("Logging Out")
        signOut();
        window.location.href = "/"
      }
   }

  }, []) 

  useEffect(()=>{
    // console.log("valid",isvalid())
  },[isvalid()])

  React.useEffect(() => {
    dispatch(userPermissions(permissionsx));
    // dispatch(userPermissionsX(permissionsx));
  }, [])

  //    useEffect(() => {
  //   const handleInvalidToken = e => {
  //     let data = localStorage.getItem("name")
  //     if(!data){
  //       alert("Called")

  //       signOut();
  //       // window.location.href = "/"
  //       logoutAction(history);
        
  //     }
  //   }
  //   window.addEventListener('storage', handleInvalidToken)
   
  //   return function cleanup() {
  //     window.removeEventListener('storage', handleInvalidToken)
  //   }
   
  // }, [logoutAction])



  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Backdrop
          className="back-drop "
          style={{ zIndex: 1500, backgroundColor: "white" }}
          open={load}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <App />

      </ThemeProvider>
    </StyledEngineProvider>
  );
};

