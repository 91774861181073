/**
 *
 * Asynchronously loads the component for ProfileManege
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProfileManege = lazyLoad(
  () => import('./index'),
  module => module.ProfileManege,
);
