/**
 *
 * Asynchronously loads the component for SendNotification
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SendNotification = lazyLoad(
  () => import('./index'),
  module => module.SendNotification,
);
