import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { AdminCreatePage } from 'app/pages/AdminCreatePage/Loadable';
import { AdminEditPage } from 'app/pages/AdminEditPage/Loadable';
import { AdminListingPage } from 'app/pages/AdminListingPage/Loadable';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { MediaCreatePage } from 'app/pages/MediaCreatePage';
import { MediaEditPage } from 'app/pages/MediaEditPage';
import { MediaListingPage } from 'app/pages/MediaListingPage';
import { RoleListingPage } from 'app/pages/RoleListingPage/Loadable';
import { RolesEditPage } from 'app/pages/RolesEditPage/Loadable';
import { Rolepage } from 'app/pages/Rolepage/Loadable';
import { BoardofDirectors } from 'app/pages/BoardofDirectors/Loadable';
import { BoardOfDirectorsAdd } from 'app/pages/BoardOfDirectorsAdd/Loadable';
import { BoardOfDirectorsEdit } from 'app/pages/BoardOfDirectorsEdit/Loadable';
import { UsersListing } from 'app/pages/UsersListing/Loadable';
import { FeedBackListing } from 'app/pages/FeedBackListing/Loadable';
import { ContactusListing } from 'app/pages/ContactusListing/Loadable';
import { BookingList } from 'app/pages/BookingList/Loadable';
import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import MainLayout from './MainLayout';
import NavigationScroll from './MainLayout/NavigationScroll';
import { ServiceCategoryCreate } from 'app/pages/ServiceCategoryCreate/Loadable';
import { ServiceCategoryEdit } from 'app/pages/ServiceCategoryEdit/Loadable';
import { ServiceCategoryListing } from 'app/pages/ServiceCategoryListing/Loadable';
import { ServiceCenterListing } from 'app/pages/ServiceCenterListing/Loadable';
import { ServiceCenterCreate } from 'app/pages/ServiceCenterCreate/Loadable';
import { ServiceCenterEdit } from 'app/pages/ServiceCenterEdit/Loadable';
import { ServiceLocationListing } from 'app/pages/ServiceLocationListing/Loadable';
import { ServiceLocationCreate } from 'app/pages/ServiceLocationCreate/Loadable';
import { ServiceLocationEdit } from 'app/pages/ServiceLocationEdit/Loadable';
import { ServicesCreatePage } from 'app/pages/ServicesCreatePage/Loadable';
import { ServicesListingPage } from 'app/pages/ServicesListingPage/Loadable';
import { ServicesEditPage } from 'app/pages/ServicesEditPage/Loadable';
import { InvoiceListing } from 'app/pages/InvoiceListing/Loadable';
import { AppointmentListingPage } from 'app/pages/AppointmentListingPage/Loadable';
import { AppointmentViewPage } from 'app/pages/AppointmentViewPage/Loadable';
import { CallToActionListing } from 'app/pages/CallToActionListing/Loadable';
import { FacilitiesListingPage } from 'app/pages/FacilitiesListingPage/Loadable';
import { FaqListingPage } from 'app/pages/FaqListingPage/Loadable';
import { FaqEditPage } from 'app/pages/FaqEditPage/Loadable'
import { FaqCreatePage } from 'app/pages/FaqCreatePage/Loadable'
import { FaqTypeListing } from 'app/pages/FaqTypeListing/Loadable'
import { FacilitiesEditPage } from 'app/pages/FacilitiesEditPage/Loadable';
import { CallToActionViewPage } from 'app/pages/CallToActionViewPage/Loadable';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContactusComponent } from 'app/components/ContactusComponent/Loadable';
import { FeedbackViewComponent } from 'app/components/FeedbackViewComponent/Loadable';
import { InjazatCardListing } from 'app/pages/InjazatCardListing/Loadable';
import { CardViewComponent } from 'app/components/CardViewComponent/Loadable';
import { ApplicationSettingPage } from 'app/pages/ApplicationSettingPage/Loadable';
import PrivateRoute from 'app/components/Route/PrivateRoute'
import ProtectedRoute from 'app/components/Route/ProtectedRoute'
import { ConsultationEditPage } from 'app/pages/ConsultationEditPage/Loadable';
import { ConsultationListingPage } from 'app/pages/ConsultationListingPage/Loadable';
import { ConsultationCreatePage } from 'app/pages/ConsultationCreatePage/Loadable';
import { NotificationListingPage } from 'app/pages/NotificationListingPage/Loadable';
import { SendNotification } from 'app/pages/SendNotification/Loadable';
import { ProfileManege } from 'app/pages/ProfileManege/Loadable';
import { ConsultrationForm } from 'app/pages/ConsultrationForm/Loadable'; 
import { CustomerEdit } from 'app/pages/CustomerEdit/Loadable';

// import { NotificationEdit } from 'app/pages/NotificationEdit/Loadable';
import { congitoConfig, isvalid } from "services/cognitouser";
import { Auth } from "aws-amplify";

export const AppLayout = () => {
  const spinLoaderShow: any = useSelector(
    (state: any) => state?.spinLoader?.spinLoaderShow
  );
  // useEffect(()=>{
  //   Auth.configure(congitoConfig);

  //   if(!isvalid()){
  //     window.location.href = "/"
  //   }

  // },[])
  return (
    <NavigationScroll>
      <ToastContainer />
      <MainLayout>
        <Switch>
          {/* Roles Routes===========> */}
          <PrivateRoute path="/app/role/list" exact component={RoleListingPage} />
          <PrivateRoute exact path="/app/role/add" component={Rolepage} />
          <PrivateRoute exact path="/app/role/edit" component={RolesEditPage} />
          {/* Roles Routes===========> */}


          {/* Admin Routes===========> */}
          <PrivateRoute exact path="/app/admin/list" component={AdminListingPage} />
          <PrivateRoute exact path="/app/admin/add" component={AdminCreatePage} />
          <PrivateRoute exact path="/app/admin/edit" component={AdminEditPage} />
          {/* Admin Routes===========> */}


          {/* Media Center Routes===========> */}
          <PrivateRoute exact path="/app/mediacenter/list" component={MediaListingPage} />
          <PrivateRoute exact path="/app/mediacenter/add" component={MediaCreatePage} />
          <PrivateRoute exact path="/app/mediacenter/edit" component={MediaEditPage} />
          {/* Media Center Routes===========> */}


          {/* service category Routes===========> */}
          <PrivateRoute exact path="/app/services/category/list" component={ServiceCategoryListing} />
          <PrivateRoute exact path="/app/services/category/add" component={ServiceCategoryCreate} />
          <PrivateRoute exact path="/app/services/category/edit" component={ServiceCategoryEdit} />
          {/* service category Routes===========> */}



          {/* service center Routes===========> */}


          {/* service location Routes===========> */}
          <PrivateRoute exact path="/app/center/location/list" component={ServiceLocationListing} />
          <PrivateRoute exact path="/app/center/location/add" component={ServiceLocationCreate} />
          <PrivateRoute exact path="/app/center/location/edit" component={ServiceLocationEdit} />
          {/* service location Routes===========> */}


          {/* Services  Routes===========> */}
          <PrivateRoute exact path="/app/services/list" component={ServicesListingPage} />
          <PrivateRoute exact path="/app/services/add" component={ServicesCreatePage} />
          <PrivateRoute exact path="/app/services/edit" component={ServicesEditPage} />
          {/* Services  Routes===========> */}

          {/* board Of directors  Routes===========> */}
          <PrivateRoute exact path="/app/boardOfdirectors/list" component={BoardofDirectors} />
          <PrivateRoute exact path="/app/boardOfdirectors/add" component={BoardOfDirectorsAdd} />
          <PrivateRoute exact path="/app/boardOfdirectors/edit" component={BoardOfDirectorsEdit} />
          {/* board Of directors  Routes===========> */}


          {/* users Routes===========> */}
          <PrivateRoute exact path="/app/customer/list" component={UsersListing} />
          <PrivateRoute exact path="/app/customer/edit" component={CustomerEdit} />
          {/* users PrivateRoutes===========> */}


          {/* feedback Routes===========> */}
          <PrivateRoute exact path="/app/feedback/list" component={FeedBackListing} />
          <PrivateRoute exact path="/app/feedback/view" component={FeedbackViewComponent} />
          {/* feedback PrivateRoutes===========> */}


          {/* contactus Routes===========> */}
          <PrivateRoute exact path="/app/contactus/list" component={ContactusListing} />
          <PrivateRoute exact path="/app/contactus/view" component={ContactusComponent} />
          {/* contactus Routes===========> */}


          {/* booking Routes===========> */}
          <PrivateRoute exact path="/app/booking/list" component={BookingList} />
          {/* booking PrivateRoutes===========> */}


          {/* invoice Routes===========> */}
          <PrivateRoute exact path="/app/invoice/list" component={InvoiceListing} />
          {/* invoice PrivateRoutes===========> */}


          {/* application-setting Routes===========> */}

          <PrivateRoute exact path="/app/application-setting" component={ApplicationSettingPage} />

          {/* application-setting PrivateRoutes===========> */}

          {/* AppointmentListingPage Routes===========> */}
          <PrivateRoute exact path="/app/appointment/list" component={AppointmentListingPage} />
          {/* AppointmentListingPage PrivateRoutes===========> */}

          {/* AppointmentViewPage Routes===========> */}
          <PrivateRoute exact path="/app/appointment/view" component={AppointmentViewPage} />
          {/* AppointmentViewPage Routes===========> */}

          {/* Injazat Card  Routes===========> */}
          <PrivateRoute exact path="/app/injazatcard/list" component={InjazatCardListing} />
          <PrivateRoute exact path="/app/injazatcard/view" component={CardViewComponent} />
          {/* <Route exact path="/app/injazatcard/edit" component={InjazatCardEdit} /> */}
          {/* Injazat Card Routes===========> */}

          {/* application-setting Routes===========> */}
          <PrivateRoute exact path="/app/calltoaction/list" component={CallToActionListing} />
          <PrivateRoute exact path="/app/calltoaction/view" component={CallToActionViewPage} />
          <PrivateRoute exact path="/app/facilities/list" component={FacilitiesListingPage} />
          <PrivateRoute exact path="/app/facilities/edit" component={FacilitiesEditPage} />
          {/* Faq  routes */}
          <PrivateRoute exact path="/app/faq/list" component={FaqListingPage} />
          <PrivateRoute exact path="/app/faq/add" component={FaqCreatePage} />
          <PrivateRoute exact path="/app/faq/edit" component={FaqEditPage} />
          <PrivateRoute exact path="/app/faqtype/list" component={FaqTypeListing} />

          {/* Consultation  routes */}
          <PrivateRoute exact path="/app/consultation/add" component={ConsultationCreatePage} />
          <PrivateRoute exact path="/app/consultation/list" component={ConsultationListingPage} />
          <PrivateRoute exact path="/app/consultation/edit" component={ConsultationEditPage} />

          <PrivateRoute exact path="/app/consultationform/list" component={ConsultrationForm} />


          {/* Notification  routes */}
          <PrivateRoute exact path="/app/notification/list" component={NotificationListingPage} />
          <PrivateRoute exact path="/app/notification/add" component={SendNotification} />

          {/* Profile Manage  route */}
          <ProtectedRoute exact path="/app/profile" component={ProfileManege} />
          {/* <PrivateRoute exact path="/app/notification/edit" component={NotificationEdit} /> */}


          {/* <Route exact path="/staff/admin" component={RolesManage} /
          > */}
          {/* <Redirect exact path="/staff" to="/staff/admin" /> */}


          <ProtectedRoute exact path="/app" component={HomePage} />
          <Redirect from="/" to="/app" />

          {/* <Route component={NotFoundPage} /> */}
        </Switch>
      </MainLayout>
      <Backdrop
        className="back-drop"
        style={{ zIndex: 1500 }}
        open={spinLoaderShow}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </NavigationScroll>
  );
};

// const PrivateRoute = ({
//   component: Component,
//   redirectLink,
//   isAuthenticated,
//   path,
//   ...props
// }) => {
//   return (
//     <Route exact path="/" component={Component} />

//     //  <Route path={""} component={<> redirectLink={redirectLink} isAuthenticate={isAuthenticated} Component={Component} </>} />
//   );
// };
