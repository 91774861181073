import React, { useEffect, useState } from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { sections } from 'data/sections';
import { checkPermission } from 'helpers/common';
import { useSelector } from 'react-redux';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = (): any => {
  const getPermission = useSelector((state: any) => state?.userPermissions?.permissions)
  const [menuListing, setMenuListing] = useState<any>(menuItem)
  const isPermission = (url: any) => {
    var sectionobj = sections.filter((x: any) => x.pathName === url);
    var isSpecific = sectionobj.find((x: any) => x.specific);
    if (isSpecific) {
      var y: any = false;
      if (sectionobj != undefined) {
        y = sectionobj.map((section: any) => {
          return checkPermission(getPermission, section.sectionName, section.subsection, section.specific)
        })
        if (y.find((x: any) => x === true)) {
          return true
        } else {
          return false
        }
      } else {
        return y;
      }
    } else {
      return false
    }
  }

  const recursive = (data: any) => {


    if (!data.children || data.children.length < 1) {
      return;
    }

    return data.children.filter(x => {

      if(x.type == "collapse"){
        // let tempX =  recursive(x);
        // if (tempX.children && tempX.children.length > 0) {
        //   return x
        // }
        // console.log("0000000000000000000",recursive(x))
        x.children = recursive(x)
        if (x.children && x.children.length > 0) {
          return x
        }
        // return recursive(x);
      }

      

      return isPermission(x.url)
    });
  }

  useEffect(() => {
    // console.log("000000000000000000000000000000000000000000000",menuItem)
    if (menuItem) {

      // console.log("asdasd", menuItem.items[1].children.filter(x => {


      //   if (x.type == "collapse") {
      //     x.children = recursive(x)
      //     if (x.children && x.children.length > 0) {
      //       return x
      //     }
    

      //   } else {
      //     console.log("getPermission", x.url, isPermission(x.url));
      //     if (isPermission(x.url)) {
      //       return x
      //     }
      //   }


      // }));

      let tempMenuListing = Object.assign({}, menuItem)
      tempMenuListing.items[1].children = menuItem.items[1].children.filter(x => {
        if (x.type == "collapse") {

          // console.log(recursive(x));
          x.children = recursive(x)
          if (x.children && x.children.length > 0) {
            return x
          }

        } else {
          // console.log("getPermission", x.url, isPermission(x.url));
          if (isPermission(x.url)) {
            return x
          }
        }


      });
      tempMenuListing.items = tempMenuListing.items.filter(x => x.children && x.children.length > 0)

      // console.log("sdasd", menuItem, tempMenuListing)
      setMenuListing(tempMenuListing)

    }
  }, [menuItem.items, getPermission])


  const checkRoles = (data:any) =>{
      if(data.children && data.children.length > 0){
        checkRoles(data.children)
      }else if(data.children && data.children.length < 0){
        return false;
      }
      return true;
  }


 return getPermission && menuListing?.items?.map(item => {

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  // return
};

export default MenuList;
