import httpservice from "./httpservice";

//  Admin Api services

export const getAdmin = (body: any = null) => {
    return httpservice.get("/admin/user", { params: body })

}
export const createAdmin = (body: any) => {
    return httpservice.post("/admin/user", body)

}
export const updateAdmin = (body: any) => {
    return httpservice.put("/admin/user", body)

}
 
export const getAdminAssignedLocations = (body:any) => {
    return httpservice.get("/admin/user/location", { params: body })
}
//  Roles Api Services 

export const getRoles = (body: any = null) => {
    return httpservice.get("/admin/roles", { params: body })

}
export const createRoles = (body: any) => {
    return httpservice.post("/admin/roles", body)

}
export const updateRoles = (body: any) => {
    return httpservice.put("/admin/roles", body)

}

//  serviceCategory Api Services 

export const getServiceCategory = (body: any = null) => {
    return httpservice.get("/service/category", { params: body })

}
export const createServiceCategory = (body: any) => {
    return httpservice.post("/service/category", body)

}
export const updateServiceCategory = (body: any) => {
    return httpservice.put("/service/category", body)

}

//  serviceCenter Api Services 

export const getServiceCenter = (body: any = null) => {
    return httpservice.get("/service/center", { params: body })

}
export const createServiceCenter = (body: any) => {
    return httpservice.post("/service/center", body)

}
export const updateServiceCenter = (body: any) => {
    return httpservice.put("/service/center", body)

}

//  serviceLocation Api Services 

export const getServiceLocation = (body: any = null) => {
    return httpservice.get("/service/location", { params: body })

}
export const createServiceLocation = (body: any) => {
    return httpservice.post("/service/location", body)

}
export const updateServiceLocation = (body: any) => {
    return httpservice.put("/service/location", body)

}

//  services Api Services 

export const getServices = (body: any = null) => {
    return httpservice.get("/services", { params: body })

}
export const createServices = (body: any) => {
    return httpservice.post("/services", body)

}
export const updateServices = (body: any) => {
    return httpservice.put("/services", body)

}
// }

export const verifyCodeValidity = (body: any) => {
    return httpservice.put("/admin/user/verify/code", body)
}
export const verifyCode = (body: any) => {
    return httpservice.post("/admin/user/verify", body)

}

export const regenerateCode = (body: any) => {
    return httpservice.put("/admin/user/regenerate", body)
}

// Customer services   
export const getCustomer = (body: any = null) => {
    return httpservice.get("/customer", { params: body })
}
export const updateCustomer = (body: any) => {
    return httpservice.put("/customer/admin/update", body)

} 
export const updateCustomerByAdmin = (body: any) => {
    return httpservice.put("/admin/user/customer/activation", body)

} 

export const updateDocumentAdmin = (body: any) => {
    return httpservice.put("/admin/user/document", body)

}
export const DocumentUpload = (files: any) => {
    return httpservice.post('/upload/file', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
} 
export const profileupload = (files: any) => {
    return httpservice.post('/upload/profileimages', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
