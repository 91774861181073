/**
 *
 * Asynchronously loads the component for FeedBackListing
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FeedBackListing = lazyLoad(
  () => import('./index'),
  module => module.FeedBackListing,
);
