/**
 *
 * Asynchronously loads the component for RegisterComponent
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RegisterComponent = lazyLoad(
  () => import('./index'),
  module => module.RegisterComponent,
);
