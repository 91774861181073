/**
 *
 * MediaListingPage
 *
 */
import { IconEdit } from '@tabler/icons';
import { AgGridCustomNormal } from 'app/components/AgGridCustomNormal';
import { MediaCenterComponent } from 'app/components/MediaCenterComponent';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { spinLoaderShow } from 'store/actions/spinLoader';
import styled from 'styled-components/macro';
import MainCard from 'ui-component/cards/MainCard';

interface Props { }

export function MediaListingPage(props: Props) {
  const [mediaList, setMediaList]: any = useState([]);
  const dispatch = useDispatch()
  //get Media data======================>

  const getMediaListingData = () => {
    axios.get('https://injazatapidev.demoz.agency/api/v1/media')
      .then(res => {
        let data = res.data.result;
        setMediaList(data)
        // console.log('MediaListingData', data);
        dispatch(spinLoaderShow(false))


      }).catch(err => {
        dispatch(spinLoaderShow(false))

      })
  }
  useEffect(() => {
    dispatch(spinLoaderShow(true))
    getMediaListingData()

  }, [])

  //get Media data======================>


  const columnDefsMedia: any = [
    {
      headerName: "Name",
      field: "name",
      filter: "agTextColumnFilter",
    },
    // {
    //   headerName: "Designation",
    //   field: "designation",
    //   filter: "agTextColumnFilter",
    // },
    {
      headerName: "Host Link",
      field: "host_link",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",

    },
    {
      headerName: "Created date",
      field: "createdAt",
      filter: "agTextColumnFilter",

    },
    {
      headerName: "Modified date",
      field: "updatedAt",
      filter: "agTextColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value);
      },

    },
    // {
    //   headerName: "Actions",
    //   field: "actions",
    //   filter: "agTextColumnFilter",
    //   cellRendererFramework: (params: any) => {
    //     return <Link to={'/app/admin/edit?uuid=' + params?.data?.uuid}><IconEdit /></Link>;
    //   }},

  ]

  return <MainCard title=" Media Listing">

    <AgGridCustomNormal rowData={mediaList} columnDefs={columnDefsMedia} />

  </MainCard>;
}

const Div = styled.div``;