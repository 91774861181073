import { SPIN_LOADERS } from '../types/spinLoader';


const spinLoaderShow = (payload: any) => {
  return {
    type: SPIN_LOADERS,
    payload: payload
  };

}
export { spinLoaderShow };