/**
 *
 * Asynchronously loads the component for ServicesCreatePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServicesCreatePage = lazyLoad(
  () => import('./index'),
  module => module.ServicesCreatePage,
);
