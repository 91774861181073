/**
 *
 * Asynchronously loads the component for CustomerEdit
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CustomerEdit = lazyLoad(
  () => import('./index'),
  module => module.CustomerEdit,
);
