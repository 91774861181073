/**
 *
 * Asynchronously loads the component for ServicesEditPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServicesEditPage = lazyLoad(
  () => import('./index'),
  module => module.ServicesEditPage,
);
