/**
 *
 * Asynchronously loads the component for FacilitiesEditPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FacilitiesEditPage = lazyLoad(
  () => import('./index'),
  module => module.FacilitiesEditPage,
);
