/**
 *
 * Asynchronously loads the component for ConsultationEditPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ConsultationEditPage = lazyLoad(
  () => import('./index'),
  module => module.ConsultationEditPage,
);
