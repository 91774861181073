import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Divider, List, Typography } from '@material-ui/core';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useSelector } from 'react-redux';
import {sections} from 'data/sections';
import { checkPermission } from 'helpers/common';

// style constant
const useStyles = makeStyles((theme: any) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: '2px',
    marginBottom: '10px',
  },
}));


// const isPermission = (url: any) => {
//   var sectionobj = sections.filter((x: any) => x.pathName === url);
//   var isSpecific = sectionobj.find((x: any) => x.specific);
//   if (isSpecific) {
//     var y: any = false;
//     if (sectionobj != undefined) {
//       y = sectionobj.map((section: any) => {
//         return checkPermission(getPermission, section.sectionName, section.subsection, section.specific)
//       })
//       if (y.find((x: any) => x === true)) {
//         return true
//       } else {
//         return false
//       }
//     } else {
//       return y;
//     }
//   } else {
//     return true
//   }
// }
 



// ===========================|| SIDEBAR MENU LIST GROUP ||=========================== //



const NavGroup = ({ item }) => {
  const [itemList,setItemList] = useState<any>([])
  const getPermission = useSelector((state: any) => state?.userPermissions?.permissions)
  const classes = useStyles();

  const isPermission = (url: any) => {
    var sectionobj = sections.filter((x: any) => x.pathName === url);
    var isSpecific = sectionobj.find((x: any) => x.specific);
    if (isSpecific) {
      var y: any = false;
      if (sectionobj != undefined) {
        y = sectionobj.map((section: any) => {
          return checkPermission(getPermission, section.sectionName, section.subsection, section.specific)
        })
        if (y.find((x: any) => x === true)) {
          return true
        } else {
          return false
        }
      } else {
        return y;
      }
    } else {
      return true
    }
  }





  useEffect(()=>{
    if(item){
      setItemList(item)
    }
  },[item,getPermission])

  // menu list collapse & items
  const items = item.children.map((menu: any) => {
  
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return (isPermission(menu.url) ?  <NavItem key={menu.id} item={menu} level={1} /> : null);
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              className={classes.menuCaption}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  className={classes.subMenuCaption}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {getPermission ? items : <></>}
      </List>

      {/* group divider */}
      <Divider className={classes.menuDivider} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
