/**
*
* AgGridCustomNormal
*
*/
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import styled from 'styled-components/macro';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { useState } from 'react'; 
import CustomDate from '../CustomUiComponents/custom-date.component'




export function AgGridCustomNormal(props: any) {
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    if (props?.setExportData) {
      props?.setExportData(params?.api)
    }

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { columnDefs, rowData, serverSideDatasource, minWidth, getRowNodeId, id, defaultExportParams } = props;
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };
  // function isFirstColumn(params:any) { var displayedColumns = params.columnApi.getAllDisplayedColumns(); var thisIsFirstColumn = displayedColumns[0] === params.column; return thisIsFirstColumn; }


  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f7f7f7e8'};
    }
  };

  const frameworkComponents = {
    agDateInput: CustomDate
  }
  // const [rowData] = useState([
  //   { make: "Toyota", model: "Celica", price: 35000 },
  //   { make: "Ford", model: "Mondeo", price: 32000 },
  //   { make: "Porsche", model: "Boxter", price: 72000 }
  // ]);

  // const [columnDefs] = useState([
  //   { field: "make", sortable: true, filter: true },
  //   { field: "model", sortable: true, filter: true },
  //   { field: "price", sortable: true, filter: true },
  // ]);
  // const onFirstDataRendered = (params) => {
  //   params.api.sizeColumnsToFit();
  // };
  // let width = 100;

  // const onGridSizeChanged = (params) => {
  //   let columnCount = params.columnApi.columnModel.gridColumns.length
  //   width = params.clientWidth / columnCount
  //   params.api.sizeColumnsToFit();
  // };


  return (
    <div className="example-wrapper">
    <div
      id="myGrid"
      style={{
        height: '80vh',
        width: '100%',
      }}
      className="ag-theme-material"
    > 
     <div className="ag-grid-custom-table" style={{"height":"92%"}}>
      <AgGridReact
        getRowStyle={getRowStyle}
        defaultCsvExportParams={defaultExportParams}
        columnDefs={columnDefs}
        defaultColDef={{
          flex: 1,
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true,
          suppressRowHoverHighlight: true,
          // lockVisible:true,
          minWidth: 230,
          autoHeight: true,
          cellStyle: { textAlign: "center", alignItems: "left", 'border-left-color': '#e2e2e2', minHeight: '100%' },
          // headerCheckboxSelection:props.disabledCheckBox ? false : isFirstColumn,
          // checkboxSelection:props.disabledCheckBox ? false : isFirstColumn
        }}
        id={id}
        animateRows={true}
        rowData={rowData}
        scroll={true}
        
        suppressDragLeaveHidesColumns={true}
        rowSelection='multiple'
        getRowNodeId={getRowNodeId}
        onGridReady={onGridReady}
        serverSideDatasource={serverSideDatasource}
        {...props}
        pagination={true}
        paginationPageSize={10}
        enableCellTextSelection={true}
        verticalScrollbar={true}
        frameworkComponents={frameworkComponents}
      />
      </div>
    </div>
    </div>

  );

};

const Div = styled.div``;
