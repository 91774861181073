
import {LOGOUT} from './types/Logout';
const initialState = {
  spinLoaderShow : false  
}

const getSpinLoader = (state:any, payload:any) => ({
  ...state,
  spinLoaderShow: payload
})

export default function LogoutReducer(state = initialState, action:any) {
   
    switch (action.type) {
      case LOGOUT:
        localStorage.removeItem('token');
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false
        };
      default:
        return state
    }
  }