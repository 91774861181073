import * as yup from "yup";

// Media Component Validations============>


const MediaValidations: any = yup.object().shape({
    name: yup.string().required("Please enter name").nullable(), 
    shortdescription: yup.string().required("Please enter short description").nullable(),
    description:yup.string().required("Please enter description").nullable(),
    host_link:yup.string().required("Please enter Link").matches(
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "Invalid Link").nullable(),
    date:yup.string().required("Please select date").nullable(),



});
export {
    MediaValidations,

}