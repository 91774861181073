/**
 *
 * DropzoneComponent
 *
 */
import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
// import { DropzoneAreaBase } from "material-ui-dropzone";
;
import { Box } from '@mui/system'
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
interface Props {
  handleSave?: any | null, filesLimit?: any | null, video?: any | null, message?: any | null, setImage?: any | null, acceptedFiles?: any | null,
}
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width:auto
`

const img: any = {
  display: 'block',
  width: 'auto',
  height: '100%',

};
const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}
const thumb: any = {
  display: 'inline-flex',
  borderRadius: 10,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 150,
  padding: 4,
  boxSizing: 'border-box'
};


export const DropzoneComponent = memo((props: Props) => {
  const [fileObjects, setFileObjects] = useState<any>([])
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: props?.acceptedFiles ? props.acceptedFiles : 'image/* , .pdf , .docx ',
    onDrop: (acceptedFiles) => { props?.handleSave(acceptedFiles) },
    onDropRejected: rejectedFiles => toast.error("Invalid file type ")
    // onDrop:  acceptedFiles => {
    //   console.log("acceptedFiles",acceptedFiles)
    //   props?.setImage(acceptedFiles.map(file => Object.assign(file, {
    //     preview: URL.createObjectURL(file)
    //   })));
    // }
  });

  return (

    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {props?.message ? <p>{props?.message}</p> : <p> </p>}
        {/* <aside>
        {thumbs}
      </aside> */}
      </Container>
    </div>

  )
});

const Div = styled.div``;
