/**
 *
 * Asynchronously loads the component for Rolepage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Rolepage = lazyLoad(
  () => import('./index'),
  module => module.Rolepage,
);
