/**
 *
 * Asynchronously loads the component for ServiceCategoryEdit
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServiceCategoryEdit = lazyLoad(
  () => import('./index'),
  module => module.ServiceCategoryEdit,
);
