import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
  } from "react";
  import DateFnsUtils from "@date-io/date-fns";
  import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,

  } from "@material-ui/pickers";
  import * as moment from "moment";
  import {
  
    TextField,
  } from "@material-ui/core";
  import DateTimePicker  from '@material-ui/lab/DateTimePicker';
  
  export default forwardRef((props:any, ref:any) => {
    const [selectedDate, setSelectedDate] = useState(null);
  
    function handleDateChange(d:any) {
      // if (d) {
      //   // set time to midnight
      //   d.setHours(0, 0, 0, 0);
      // }
      setSelectedDate(d);
      // props.onDateChanged(moment(selectedDate).format("yyyy-MM-dd"));
    }
  
    useEffect(props.onDateChanged, [selectedDate]);
  
    useImperativeHandle(ref, () => {
      return {
        getDate: () => {
          return selectedDate;
        },
        setDate: (d:any) => {
          handleDateChange(d);
        },
      };
    });
  
    return (
      // <MuiPickersUtilsProvider utils={DateFnsUtils}>
      //   <KeyboardDateTimePicker
      //     variant="inline"
      //     format="yyyy-MM-dd HH:mm:SS"
      //     value={selectedDate}
      //     onChange={handleDateChange}
      //   />
      // </MuiPickersUtilsProvider>
    //   <DateTimePicker
    //       label="DateTimePicker"
    //       inputVariant="outlined"
    //       format="yyyy-MM-dd HH:mm:SS"
    //       value={selectedDate}
    //       ampm={false}
    //       allowKeyboardControl={true}
    //       onChange={handleDateChange}
    //     />
        <DateTimePicker 
        label="DateTimePicker"
          value={selectedDate}
          onChange={handleDateChange}
          ampm={false}
          renderInput={(params) => <TextField size='medium'  fullWidth  {...params} />}
     
          />
    );
  });
  