export const sections: any = [

  // admin============>
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/admin/add",
    sectionName: "Admin",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/admin/edit",
    sectionName: "Admin",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/admin/list",
    sectionName: "Admin",
  },
  // admin============>
  
  
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/customer/list",
    sectionName: "Customers",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/customer/edit",
    sectionName: "Customers",
  },


  // Roles============>
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/role/add",
    sectionName: "Roles",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/role/edit",
    sectionName: "Roles",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/role/list",
    sectionName: "Roles",
  },
  // Roles============>

  // MediaCenter============>

  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/mediacenter/add",
    sectionName: "MediaCenter",

  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/mediacenter/edit",
    sectionName: "MediaCenter",

  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/mediacenter/list",
    sectionName: "MediaCenter",
  },
  // MediaCenter============>

  // Services Category============>
  {
    specific: "create",
    permission: true,
    subsection: "Category",
    pathName: "/app/services/category/add",
    sectionName: "Services",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Category",
    pathName: "/app/services/category/edit",
    sectionName: "Services",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Category",
    pathName: "/app/services/category/list",
    sectionName: "Services",
  },
 
  // Services Center============>

  // Services Location============>
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/center/location/add",
    sectionName: "Centers Locations",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/center/location/edit",
    sectionName: "Centers Locations",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/center/location/list",
    sectionName: "Centers Locations",
  },
  // Services Location============>

  // Services Services============>
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/services/add",
    sectionName: "Services",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/services/edit",
    sectionName: "Services",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/services/list",
    sectionName: "Services",

  },
  // Services Services============>


  // Services Users============>

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/users/list",
    sectionName: "Admin",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/users/list",
    sectionName: "Admin",

  },
  // Services Users============>


  // Services Feedback============>
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/feedback/list",
    sectionName: "Customer Voice",

  },
  // Services Feedback============>

  // Services ContactUs============>

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/contactus/list",
    sectionName: "Contact Us",

  },
  // Services ContactUs============>

  // Services booking============>
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/booking/list",
    sectionName: "Booking",

  },
  // Services booking============>


  // Services Invoice============>
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/invoice/list",
    sectionName: "Invoice",

  },
  // Services Invoice============>

  // Services Settings============>
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/application-setting",
    sectionName: "Application Setting",

  },

  // appointment=========>

  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/appointment/add",
    sectionName: "Appointments Inquiries",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/appointment/edit",
    sectionName: "Appointments Inquiries",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/appointment/list",
    sectionName: "Appointments Inquiries",

  },

  // appointment=========>

  // Call To Actions=========>

  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/calltoaction/add",
    sectionName: "Call to Actions Inquiries",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/calltoaction/edit",
    sectionName: "Call to Actions Inquiries",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/calltoaction/list",
    sectionName: "Call to Action Inquiries",

  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/calltoaction/view",
    sectionName: "Call to Action Inquiries",

  },
  // Call To Actions=========>

  // Appointment View=========>
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/appointment/view",
    sectionName: "Appointments Inquiries",

  },
  // Appointment View=========>


  // Facilities =========>

  {
    specific: "read",
    permission: true,
    subsection: "Category",
    pathName: "/app/facilities/list",
    sectionName: "Services Channels",

  },
  {
    specific: "update",
    permission: true,
    subsection: "Category",
    pathName: "/app/facilities/edit",
    sectionName: "Services Channels",

  },

  // Facilities =========>

  // Consultation =========>
  {
    specific: "create",
    permission: true,
    subsection: "Category",
    pathName: "/app/consultation/add",
    sectionName: "Consultations",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Category",
    pathName: "/app/consultation/edit",
    sectionName: "Consultations",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Category",
    pathName: "/app/consultation/list",
    sectionName: "Consultations",

  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/consultationform/list",
    sectionName: "Consultations Inquiries",

  },
  // Consultation =========>


  // injazatCard =========>

  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/injazatcard/add",
    sectionName: "Injazat Card",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/injazatcard/edit",
    sectionName: "Injazat Card",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/injazatcard/list",
    sectionName: "Injazat Card",

  },

  // Faq =======
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faq/add",
    sectionName: "FAQ'S",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faq/edit",
    sectionName: "FAQ'S",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faq/list",
    sectionName: "FAQ'S",

  },

  // Faq Types =======
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faqtype/add",
    sectionName: "Faq",
  },

  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faqtype/edit",
    sectionName: "Faq",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/faqtype/list",
    sectionName: "Faq",

  },
  // Notification =======>


  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/app/notification/list",
    sectionName: "Notifications",

  },

  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/app/notification/add",
    sectionName: "Notifications",
  },




];
