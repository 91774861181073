/**
 *
 * Asynchronously loads the component for ServicesListingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServicesListingPage = lazyLoad(
  () => import('./index'),
  module => module.ServicesListingPage,
);
