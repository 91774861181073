/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

// import { configureAppStore } from 'store/configureStore';
import { store } from 'store';

import reportWebVitals from 'reportWebVitals';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import { Application } from 'Application';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// // pick a date util library
// import DateFnsUtils from '@date-io/date-fns';

import themes from 'styles/themes';

// Initialize languages
import './locales/i18n';
import 'assets/scss/style.scss';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
    <HelmetProvider>
      <React.StrictMode>
        <Application />
        {/* <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />

            <App />
          </ThemeProvider>
        </StyledEngineProvider> */}
      </React.StrictMode>
    </HelmetProvider>
    </LocalizationProvider>
    {/* </MuiPickersUtilsProvider> */}
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
