/**
 *
 * Asynchronously loads the component for CallToActionListing
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CallToActionListing = lazyLoad(
  () => import('./index'),
  module => module.CallToActionListing,
);
