/**
 *
 * Asynchronously loads the component for RolesEditPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RolesEditPage = lazyLoad(
  () => import('./index'),
  module => module.RolesEditPage,
);
