/**
 *
 * MediaCenterComponent
 *
 */
import styled from 'styled-components/macro';
import React, { useEffect } from "react";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { Card, CardHeader, CardMedia } from '@mui/material';


// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  CardContent,
  CardActions
} from '@mui/material';
import AnimateButton from "ui-component/extended/AnimateButton";
import { DateField, SwitchFieldDefault, TextFieldControlled } from "../CustomUiComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { MediaValidations } from './Validations/media.validation';
import Avatar from '@mui/material/Avatar';
import { DropZoneDialogComponent } from 'app/components/DropZoneDialogComponent/Loadable'
import { makeStyles } from '@material-ui/styles'
import { padding } from '@material-ui/system';
import { DropzoneComponent } from '../DropzoneComponent';
import Viewer from 'react-viewer';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

// import DeleteIcon  DeleteIcon';from '@material-ui/icons/

import { IconTrash } from '@tabler/icons'
import MainCard from 'ui-component/cards/MainCard';
import axios from 'axios';
import { toast } from 'react-toastify';



const styles: any = {
  avatar: {
    height: "100px",
    width: "100px",
    padding: "15px"
  },
  swiperContainer: {
    ".swiper-slide": {
      width: '150px'
    }
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain",
    borderRadius: '10px',
    // marginLeft: '4rem',
  },
}




const defaultFormValues: any = {



};

const useStyles = makeStyles(styles)
interface Props { }
export function MediaCenterComponent(props: Props) {
  const dispatch=useDispatch()
  const [listingImage, setListingImage] = useState<any>([])
  const [bannerImage, setBannerImage] = useState<any>([])
  const [visibleImage, setVisibleImage] = useState<any>(false);
  const [visibleBanner, setVisibleBanner] = useState<any>(false);
  const [galleryImage, setGalleryImage] = useState<any>([
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80 " },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
    { src: "https://images.unsplash.com/photo-1648631272295-f89ee9847bf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" },
  ])
  const classes = useStyles()


  // React Hook Form==========>

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",

    //Validations=============>

    defaultValues: defaultFormValues,
    resolver: yupResolver(MediaValidations),
  });

  const onSubmit = (data: any) => {

    // data.image = listingImage
    // data.bannerImage = bannerImage
    // data.gallery_image = galleryImage
    // console.log(data);


    //post data======================>
    axios.post('https://injazatapidev.demoz.agency/api/v1/media', data)
      .then(res => {
        let Data = res.data.result;
        // console.log("Mediacenter", Data);
        // dispatch(spinLoaderShow(false))
        toast.success("Record inserted Successfully")

      }).catch((err) => {
        // dispatch(spinLoaderShow(false))
      })
    //post  data======================>
  }



  const handleSaveGallary = (files: any) => {
    files = files?.map((item: any, index) => {
      return { src: URL.createObjectURL(item) }
    })
    let mergArr = [...galleryImage, ...files]
    setGalleryImage(mergArr)
    // console.log("urls", files)


    // console.log("files", files)
  }

  const handleSaveListing = (files: any) => {
    files = files?.map((item: any, index) => {
      return { src: URL.createObjectURL(item) }
    })
    setListingImage(files)
  }
  const handleSaveBanner = (files: any) => {
    files = files?.map((item: any, index) => {
      return { src: URL.createObjectURL(item) }
    })
    setBannerImage(files)
  }
  useEffect(() => {
    // console.log("bannerImage", bannerImage)
  }, [bannerImage])

  return <>

    {/* sx={{ mt: 2 }} */}
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={1}  >

          <Grid item md={12} style={{ marginTop: "10px", display: "flex", justifyContent: 'right' }}>

            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete="off"
              label="Status"
              control={control}
              isLoading={true}
              setValue={setValue}
              helperText={
                errors?.status &&
                errors?.status?.message
              } />

          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <TextFieldControlled
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete='off'
              label="*name"
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <TextFieldControlled
              errors={!!errors?.host_link}
              fieldName="host_link"
              type="text"
              autoComplete='off'
              label="Hosted host_link *"
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.host_link && errors?.host_link?.message}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6} marginTop="16px">
            <DateField
              errors={!!errors?.date}
              fieldName="date"
              autoComplete='off'
              label="Date *"
              setValue={setValue}
              control={control}
              inputVariant="outlined"
              iProps={{
                disablePast: true
              }}
              helperText={errors?.date && errors?.date?.message}
              valueGot={""}

            />
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <TextFieldControlled
              errors={!!errors?.shortdescription}
              fieldName="shortdescription"
              type="text"
              autoComplete='off'
              label="Short Description *"
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.shortdescription && errors?.shortdescription?.message}
            />
          </Grid>
          <Grid item xs={6} md={12} sm={6}>
            <TextFieldControlled
              errors={!!errors?.description}
              fieldName="description"
              type="text"
              autoComplete='off'
              label="Description *"
              multiline
              rows={3}
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.description && errors?.description?.message}
            />
          </Grid>

          {/* use dropzone============> */}

          <Grid item xs={12} md={12} sm={12} sx={{ mt: 5, display: "flex" }}>

            <Grid item xs={6} md={6} sm={6}>

              <Grid container  >

                <Grid item xs={6} md={4} sx={{ justifyContent: "center" }}>

                  <Divider textAlign="left"> <Typography variant="h5" gutterBottom> Single Image </Typography></Divider>
                  <img onClick={() => setVisibleImage(true)} src={listingImage[0]?.src ? listingImage[0]?.src : "https://cdn-icons-png.flaticon.com/512/179/179378.png"}
                    style={{ width: '100px', height: '100px', borderRadius: 10, objectFit: "cover" }} />

                  {listingImage.length > 0 && <Viewer
                    visible={visibleImage}
                    onClose={() => { setVisibleImage(false); }}
                    images={[{ src: listingImage[0]?.src, alt: '' }]}
                    noNavbar={true}
                    noToolbar={true}
                    onMaskClick={() => setVisibleImage(false)}
                    drag={false}

                  />}
                </Grid>
                <Grid item md={6} sm={6} xs={6} sx={{ mt: 3 }}>

                  <DropzoneComponent handleSave={handleSaveListing} filesLimit={null} video={null} message={null} setImage={setListingImage} />

                </Grid>

              </Grid>

            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Grid container  >
                <Grid item xs={6} md={4} sx={{ justifyContent: "center" }}>
                  <Divider textAlign="left"> <Typography variant="h5" gutterBottom> Banner Image </Typography></Divider>
                  <img onClick={() => setVisibleBanner(true)} src={bannerImage[0]?.src ? bannerImage[0]?.src : "https://cdn-icons-png.flaticon.com/512/179/179378.png"}
                    style={{ width: '100px', height: '100px', borderRadius: 10, objectFit: "cover" }} />
                  {bannerImage.length > 0 && <Viewer
                    visible={visibleBanner}
                    onClose={() => { setVisibleBanner(false); }}
                    images={[{ src: bannerImage[0]?.src, alt: '' }]}
                    noNavbar={true}
                    noToolbar={true}
                    onMaskClick={() => setVisibleBanner(false)}

                  />}
                </Grid>
                <Grid item md={6} sm={6} xs={6} sx={{ mt: 3 }}>
                  <DropzoneComponent handleSave={handleSaveBanner} filesLimit={null} video={null} message={null} setImage={setBannerImage} />

                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/* use dropzone============> */}

          {/* swiper======================> */}

          <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
            <Card>
              <CardHeader title="Gallery Image">
              </CardHeader>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{}} >
                    <Swiper

                      modules={[Navigation, A11y]}
                      spaceBetween={0}
                      slidesPerView={5}
                      navigation
                    >
                      {galleryImage?.map((item: any, index: any) => {
                        return <SwiperSlide>

                          <Grid item xs={12} md={12} sm={12} sx={{}}>
                            <img src={item.src} className={classes.img} />
                          </Grid>
                          <Grid item xs={12} md={12} sm={12} sx={{ mt: 2, ml: 3 }}>
                            <Button
                              variant="contained"
                              color="error"
                              className={classes.button}
                              startIcon={<IconTrash />}
                              onClick={
                                () => {
                                  const deletImg = galleryImage?.filter((fileObjects: any, i: any) => index !== i);
                                  setGalleryImage(deletImg)

                                }
                              }
                            >
                              Delete
                            </Button>
                          </Grid>
                        </SwiperSlide>
                      })}

                    </Swiper>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions >

                <Grid container>
                  <Grid item xs={12} md={12} sm={12} >
                    <DropzoneComponent handleSave={handleSaveGallary} filesLimit={null} video={null} message={null} setImage={setBannerImage} />
                  </Grid>
                </Grid>
              </CardActions>

            </Card>
          </Grid>

          {/* swiper======================> */}

        </Grid>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"

        >

          <Grid item >
            <Stack direction="row">
              <AnimateButton>
                <Button variant="contained" type="submit"
                  className={classes.button}>
                  Submit
                </Button>
              </AnimateButton>
            </Stack>

          </Grid>
        </Grid>
      </CardActions>
    </form>


  </>
}

const Div = styled.div``;



