import { USER_PERMISSIONSX } from './types/user';

const initialState = {
    permissions: null
};

const getPermissionX = (state:any, payload:any) => ({
    ...state,
    permissions: payload
})

const userPermissionsX = (state = initialState, action:any) => {
    switch (action.type) {
        case USER_PERMISSIONSX:
            return getPermissionX(state, action.payload);

        default:
            return state
    }
}

export default userPermissionsX;