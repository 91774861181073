// assets
import {
  IconKey,
  IconReceipt2,
  IconBug,
  IconBellRinging,
  IconPhoneCall,
  IconMapPin,
  IconGps,
  IconUser,
  IconShieldLock,
  IconBox,
  IconCalendarEvent,
  IconCar,
  IconClick,
  IconWiperWash,
  IconSettings,
  IconBoxPadding,
  IconPackage,
  IconUsers,
  IconNotes,
  IconPlus,
  IconDeviceTv,
  IconRoute,
  IconBusinessplan,
  IconServicemark,
  IconShoppingCart,
  IconBuilding,
  IconUserCheck,
  IconBuildingBank,
  IconFileUpload,
  IconMessage2,
  IconAtom2,
  IconChartArcs,
  IconChartArcs3,
  IconSend,
  IconPlayCard,
  IconQuestionMark,
  IconCalendar

} from '@tabler/icons';

// constant
// const icons = {
//   IconKey,
//   IconReceipt2,
//   IconBug,
//   IconBellRinging,
//   IconPhoneCall,
// };

// ===========================|| EXTRA PAGES MENU ITEMS ||=========================== //
// ===========================|| Side Bar ||=========================== //


const pages = {
  id: 'pages',
  type: 'group',
  children: [
    {
      id: 'admin',
      title: 'Administration',
      type: 'collapse',
      icon: IconUser,
      children: [

        {
          id: 'role',
          title: 'Roles',
          icon: IconShieldLock,
          type: 'collapse',
          children: [
            {
              id: 'role-list',
              title: 'Roles Listing',
              type: 'item',
              icon: IconNotes,
              url: '/app/role/list',
              target: false,
            },
            {
              id: 'role-add',
              title: 'Role Add',
              type: 'item',
              icon: IconPlus,
              url: '/app/role/add',
              target: false,
            },

          ]
        },
        {
          id: 'admin',
          title: 'Admin',
          icon: IconUser,
          type: 'collapse',
          children: [
            {
              id: 'admin-list',
              title: 'Admin Listing',
              type: 'item',
              icon: IconNotes,
              url: '/app/admin/list',
              target: false,
            },
            {
              id: 'admin-add',
              title: 'Admin Add',
              type: 'item',
              icon: IconPlus,
              url: '/app/admin/add',
              target: false,
            },

          ]
        },
      ],
    },

    // Media center====================>
    // {
    //   id: '558',
    //   title: 'Board Of Director',
    //   icon: IconUsers,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'board-list',
    //       title: 'Listing',
    //       icon: IconNotes,
    //       type: 'item',
    //       url: '/app/boardOfdirectors/list',
    //       target: false,

    //     },
    //     {
    //       id: 'board-add',
    //       title: 'Add',
    //       icon: IconPlus,
    //       type: 'item',
    //       url: '/app/boardOfdirectors/add',
    //       target: false,

    //     },
    //   ],

    // },

    // {
    //   id: 'media',
    //   title: 'Media Center',
    //   icon: IconDeviceTv,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'media-list',
    //       title: 'Listing',
    //       type: 'item',
    //       icon: IconNotes,
    //       url: '/app/mediacenter/list',
    //       target: false,
    //     },
    //     {
    //       id: 'media-add',
    //       title: 'Add',
    //       type: 'item',
    //       icon: IconPlus,
    //       url: '/app/mediacenter/add',
    //       target: false,
    //     },

    //   ]
    // },

    // Facitilites ===>
    {
      id: 'facilities',
      title: 'Services Channels',
      icon: IconDeviceTv,
      type: 'collapse',
      children: [
        {
          id: 'facilities-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: '/app/facilities/list',
          target: false,
        },


      ]
    },

    // Services ====================>
    {

      id: 'Services1',
      title: 'Centers  Locations',
      icon: IconMapPin,
      type: 'collapse',
      children: [
        {
          id: 'location-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: '/app/center/location/list',
          target: false,
        },
        {
          id: 'location-add',
          title: 'Add',
          type: 'item',
          icon: IconPlus,
          url: '/app/center/location/add',
          target: false,
        },

      ]

    },

    {
      id: '220',
      title: 'Services',
      icon: IconBox,
      type: 'collapse',
      children: [
        {

          id: 'Services',
          title: 'Service Category',
          icon: IconBoxPadding,
          type: 'collapse',
          children: [
            {
              id: 'category-list',
              title: 'Listing',
              type: 'item',
              icon: IconNotes,
              url: '/app/services/category/list',
              target: false,
            },


          ]

        },

        // {

        //   id: 'Services',
        //   title: 'Service Centers',
        //   icon: IconBuilding,
        //   type: 'collapse',
        //   children: [
        //     {
        //       id: 'Centers-list',
        //       title: 'Listing',
        //       type: 'item',
        //       icon: IconNotes,
        //       url: '/app/services/center/list',
        //       target: false,
        //     },

        //   ]

        // },



        {

          id: 'Services2',
          title: 'Services',
          icon: IconBox,
          type: 'collapse',
          children: [
            {
              id: 'Service-list',
              title: 'Listing',
              type: 'item',
              icon: IconNotes,
              url: '/app/services/list',
              target: false,
            },
            {
              id: 'Service-add',
              title: 'Add',
              type: 'item',
              icon: IconPlus,
              url: '/app/services/add',
              target: false,
            },

          ]

        },


      ]


    },


    // Inquiry ====================>

    {
      id: '222',
      title: 'Customer Inquries',
      icon: IconAtom2,
      type: 'collapse',
      children: [
        {
          id: 'appointment',
          title: 'Appointments',
          icon: IconCalendar,
          type: 'item',
          url: '/app/appointment/list',
          target: false,

        },
        {
          id: 'calltoactions',
          title: 'Call to Actions',
          icon: IconUser,
          type: 'item',
          url: '/app/calltoaction/list',
          target: false,

        },
        {
          id: 'consultationform',
          title: 'Consultations',
          icon: IconUser,
          type: 'item',
          url: '/app/consultationform/list',
          target: false,

        },

    

      ],

    },

    // Inquiry ====================>

    // Consultation ====================>

    {
      id: '555',
      title: 'Consultations',
      icon: IconAtom2,
      type: 'collapse',
      children: [
        {
          id: 'consultation-list',
          title: 'Listing',
          icon: IconNotes,
          type: 'item',
          url: '/app/consultation/list',
          target: false,

        },


        {
          id: 'consultation-add',
          title: 'Add',
          icon: IconPlus,
          type: 'item',
          url: '/app/consultation/add',
          target: false,

        },
      ],

    },
    // Consultation ====================>


    // users===================>
    {
      id: 'users',
      title: 'Customers',
      icon: IconUserCheck,
      type: 'collapse',
      children: [
        {
          id: 'users-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: "/app/customer/list",
          target: false,
        },
      ]
    },
    // users===================>

    // feedback===================>

    {
      id: 'feedback',
      title: 'Customer Voice',
      icon: IconMessage2,
      type: 'collapse',
      children: [
        {
          id: 'feedback-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: "/app/feedback/list",
          target: false,
        },
      ]
    },

    // feedback===================>




    // Notification ===================>


    {
      id: '666',
      title: 'Notifications',
      icon: IconBellRinging,
      type: 'collapse',
      children: [
        {
          id: 'Notification-list',
          title: 'Listing',
          icon: IconNotes,
          type: 'item',
          url: '/app/notification/list',
          target: false,

        },
        {
          id: 'Notification-add',
          title: 'Send',
          icon: IconSend,
          type: 'item',
          url: '/app/notification/add',
          target: false,

        },

      ],

    },

    // Notification ===================>



    // Injazat Card ===================>

    {
      id: 'injazatcard',
      title: 'Injazat Card',
      icon: IconPlayCard,
      type: 'collapse',
      children: [
        {
          id: 'injazat-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: "/app/injazatcard/list",
          target: false,
        },


      ]
    },


    // Injazat Card ===================>

    // contact Us===================>

    {
      id: 'contactus',
      title: 'Contact Us',
      icon: IconPhoneCall,
      type: 'collapse',
      children: [
        {
          id: 'contactus-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: "/app/contactus/list",
          target: false,
        },
      ]
    },

    {

      id: '939',
      title: "FAQ'S",
      icon: IconQuestionMark,
      type: 'collapse',
      children: [
        {
          id: 'faq-list',
          title: 'Listing',
          type: 'item',
          icon: IconNotes,
          url: '/app/faq/list',
          target: false,
        },
        {
          id: 'faq-add',
          title: 'Add',
          type: 'item',
          icon: IconPlus,
          url: '/app/faq/add',
          target: false,
        },

      ]

    },

    /// FAq 
    // {
    //   id: '939',
    //   title: 'Faq',
    //   icon: IconQuestionMark,
    //   type: 'collapse',
    //   children: [
    //     // {

    //     //   id: 'faq-type',
    //     //   title: 'Faq Type',
    //     //   icon: IconBoxPadding,
    //     //   type: 'collapse',
    //     //   children: [
    //     //     {
    //     //       id: 'type-list',
    //     //       title: 'Listing',
    //     //       type: 'item',
    //     //       icon: IconNotes,
    //     //       url: '/app/faqtype/list',
    //     //       target: false,
    //     //     },


    //     //   ]

    //     // },


    //     {

    //       id: 'faq',
    //       title: 'Faq',
    //       icon: IconMapPin,
    //       type: 'collapse',
    //       children: [
    //         {
    //           id: 'faq-list',
    //           title: 'Listing',
    //           type: 'item',
    //           icon: IconNotes,
    //           url: '/app/faq/list',
    //           target: false,
    //         },
    //         {
    //           id: 'faq-add',
    //           title: 'Add',
    //           type: 'item',
    //           icon: IconPlus,
    //           url: '/app/faq/add',
    //           target: false,
    //         },

    //       ]

    //     },
    //   ]


    // },

    // invoice===================>
    // {
    //   id: 'Invoice',
    //   title: 'Invoice',
    //   icon: IconBuildingBank,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'invoice-list',
    //       title: 'Listing',
    //       type: 'item',
    //       icon: IconNotes,
    //       url: "/app/invoice/list",
    //       target: false,
    //     },
    //   ]
    // },

    // invoice===================>


    // application-setting ===================>
    {
      id: 'applicationsetting',
      title: 'Application Setting',
      icon: IconSettings,
      type: 'collapse',
      children: [
        {
          id: 'setting',
          title: 'Setting',
          type: 'item',
          icon: IconSettings,
          url: "/app/application-setting",
          target: false,
        },
      ]
    },


    // application-setting ===================>



  ],


};

export default pages;
