/**
 *
 * Asynchronously loads the component for UsersListing
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UsersListing = lazyLoad(
  () => import('./index'),
  module => module.UsersListing,
);
