export const permissionsx = [

  //users=========>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Admin"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Admin"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Admin"
  },

  //users=========>


  //roles=========>
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Roles"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Roles"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Roles"
  },
  //roles=========>
  

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Customers"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Customers"
  },

 
  //MediaCenter=========>

  //Services=========>
  {
    "specific": "read",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Services"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Services"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Services"
  },
 
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Centers Locations"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Centers Locations"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Centers Locations"
  },
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Services"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Services"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Services"
  },

  //Services=========>

  

  //Users=========>

  //Feedback=========>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Customer Voice"
  },

  //Feedback=========>

  //ContactUs=========>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Contact Us"
  },

  //ContactUs=========>

  //Booking=========>
 
  //Booking=========>

  //Invoice=========>


  //Settings=========>
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Application Setting"
  },
  //Settings=========>


  // Inquiry=========>

  // Appointment=========>
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Appointments Inquiries"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Appointments Inquiries"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Appointments Inquiries"
  },
  // Appointment=========>

  // Call To Actions=========>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Call to Action Inquiries"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Call to Action Inquiries"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Call to Action Inquiries"
  },
  //Call To Actions=========>






  // facilities=======>
  {
    "specific": "read",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Services Channels"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Services Channels"
  },

  // facilities=======>
  // Consultation=======>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Consultations"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Consultations"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Category",
    "sectionName": "Consultations"
  },
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Consultations Inquiries"
  },

  // Consultation=======>


  // injazatCard=======>

  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Injazat Card"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Injazat Card"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Injazat Card"
  },

  // injazatCard=======>

  // Application Settings=======>
  // {
  //   "specific": "read",
  //   "permission": true,
  //   "subsection": "Manage",
  //   "sectionName": "Settings"
  // },
  // {
  //   "specific": "create",
  //   "permission": true,
  //   "subsection": "Manage",
  //   "sectionName": "Settings"
  // },
  // {
  //   "specific": "update",
  //   "permission": true,
  //   "subsection": "Manage",
  //   "sectionName": "Settings"
  // },

  // Faq =======
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "FAQ'S"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "FAQ'S"
  },
  {
    "specific": "update",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "FAQ'S"
  },


  // Notification
  {
    "specific": "read",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Notifications"
  },
  {
    "specific": "create",
    "permission": true,
    "subsection": "Manage",
    "sectionName": "Notifications"
  },



];
