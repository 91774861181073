/**
 *
 * Asynchronously loads the component for FacilitiesListingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FacilitiesListingPage = lazyLoad(
  () => import('./index'),
  module => module.FacilitiesListingPage,
);
