/**
 *
 * Asynchronously loads the component for ConsultationCreatePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ConsultationCreatePage = lazyLoad(
  () => import('./index'),
  module => module.ConsultationCreatePage,
);
