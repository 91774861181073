/**
 *
 * Asynchronously loads the component for ServiceCategoryCreate
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ServiceCategoryCreate = lazyLoad(
  () => import('./index'),
  module => module.ServiceCategoryCreate,
);
