import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import userPermissions from './permissions'
import spinLoader from './spinLoaderReducer'
import logoutReducer from './logoutReducer'

import userPermissionsX from './permissionsX'
// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    userPermissions :userPermissions,
    userPermissionsX:userPermissionsX,
    spinLoader,
    logoutReducer

});

export default reducer;
