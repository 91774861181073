/**
 *
 * MediaCreatePage
 *
 */
import { MediaCenterComponent } from 'app/components/MediaCenterComponent';
import * as React from 'react';
import styled from 'styled-components/macro';
import MainCard from 'ui-component/cards/MainCard';

interface Props { }

export function MediaCreatePage(props: Props) {
  return <MainCard title=" Media Create">

    <MediaCenterComponent />
  </MainCard>;
}

const Div = styled.div``;
