import React from "react";

import { Route, Redirect } from "react-router-dom";
 import * as cogUser from "services/cognitouser";

const ProtectedRoute = ({ component: Component, ...rest }:any) => {
  return (
    <Route
      exact
      sensitive
      {...rest}
      render={(props) => {
        return cogUser.isvalid() ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
        // const token = sessionStorage.getItem("token");
        // return token ? (
        //   <Component {...rest} {...props} />
        // ) : (
        //   <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        // );
      }}
    />
  );
};

export default ProtectedRoute;
