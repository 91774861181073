/**
 *
 * Asynchronously loads the component for ConsultationListingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ConsultationListingPage = lazyLoad(
  () => import('./index'),
  module => module.ConsultationListingPage,
);
