/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import MainLayout from 'app/layouts/MainLayout';
import NavigationScroll from './layouts/MainLayout/NavigationScroll';
import { Staff } from './pages/Staff';
import { AppLayout } from './layouts/App';
import { LoginComponent } from './components/LoginComponent/Loadable';
import { SecretComponent } from './components/SecretComponent/Loadable';
import { RegisterComponent } from './components/RegisterComponent/Loadable';
import { SecretPage } from 'app/pages/SecretPage/Loadable';
import { createStore } from "redux";
import { Provider, useDispatch } from "react-redux";
import { getRoles } from 'services/userServices';
import { signOut } from "services/cognitouser";
import { useHistory } from "react-router-dom";
import { userPermissions } from "store/actions/permission";
import { toast } from "react-toastify";
import { ForgotPasswordLayout } from './components/ForgotPasswordLayout/Loadable';



export function App() {

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    console.log("test", process.env);

  }, []);
  // const checkRoles = () => {
  //   let roleId = sessionStorage.getItem("roleId");
  //   // dispatch(userPermissions(permissionsx));

  //   if (roleId) {
  //     getRoles({ uuid: roleId }).then((res: any) => {
  //      console.log("role data",res)

  //       if (res[0]["status"]) {
  //         console.log("res[0][permissions]",res[0]["permissions"])
  //         dispatch(userPermissions(res[0]?.permissions));
  //         //dispatch(userPermissions(permissions));
  //       } else {
  //         signOut();
  //         toast.error("Your role is disabled! Contact Admin");
  //         history.push("/")
  //       }
  //     })
  //   }

  // }

  // useEffect(() => {

  //   checkRoles();
  //   //GET FROM SessionStorage

  //   return () => {
  //     toast.error("Your role is disabled! Contact Admin");

  //   };
  // }, [])

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Admin Portal"
        defaultTitle="Injazat Admin Portal"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Admin Portal" />
      </Helmet>

      <Switch>
        <Route exact path="/" component={LoginComponent} />
        <Route exact path="/register" component={RegisterComponent} />
        <Route exact path="/forgotpassword" component={ForgotPasswordLayout} />

        <Route path="/app" component={AppLayout} />
        <Route path="/secret" exact component={SecretPage} />
        {/* <Route path="" component={NotFoundPage} /> */}
      </Switch>

      <GlobalStyle />
    </BrowserRouter>
  );
}
